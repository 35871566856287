<template>
  <div>
    <h2 class="text-5xl uppercase text-brand-red font-amatic-sc">
      {{ title }}
    </h2>
    <div class="grid gap-8 mt-10 md:grid-cols-2 lg:grid-cols-4">
      <router-link :to="item.route" v-for="(item, index) in items" :key="index">
        <Card :input="item" :color="color" />
      </router-link>
    </div>
  </div>
</template>

<script>
import Card from "./card.vue";

export default {
  props: ["items", "title", "color"],
  components: { Card },
};
</script>
