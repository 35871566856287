<template>
  <div
    class="grid lg:grid-cols-3 h-92 md:h-auto shadow-xl overflow-hidden w-full"
  >
    <div class="space-y-6 px-8 py-6">
      <h2 class="mt-10 text-4xl uppercase text-brand-red font-amatic-sc">
        Online Kinderrechtekongress 2021
        <br />
        Schön, dass Sie da sind!
      </h2>
      <p>
        Wir begrüßen Sie recht herzlich zum diesjährigen Kinderrechtekongress
        unter dem Thema „aufwachsen-gerecht-gestalten – Kinderrechte in Alltag
        und Politik“.
      </p>
      <p>
        In regelmäßigen Abständen richtet OUTLAW.die Stiftung gemeinsam mit
        örtlichen Kooperationspartnern bundesweite Kongress rund um das Thema
        Kinderrechte aus. In diesem Jahr freuen wir uns auf den ersten
        Online-Kongress und wünschen Ihnen eine gute Zeit.
      </p>
    </div>
    <div class="relative col-span-2">
      <div class="absolute inset-0 grid">
        <img
          class="object-cover w-full h-full"
          src="@/assets/Bilder/csm_teaser-kinderrechte_ac1bb199c0.jpg.pagespeed.ce.fjyMrPmKKE.jpg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "introduction",
};
</script>

<style scoped></style>
