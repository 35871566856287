<template>
  <div class="px-5 py-20 mx-auto space-y-20 max-w-screen-xl lg:px-0">
    <Intro />
    <div>
      <Button />
    </div>
    <Links
      :items="meetings[0].foren"
      :title="`Das sind die Foren`"
      :color="`border-brand-green`"
    />
    <Links
      :items="meetings[0].workshops"
      :title="`Das sind die Workshops`"
      :color="`border-brand-blue`"
    />
  </div>
</template>

<script>
import Intro from "../components/home/introduction.vue";
import Links from "../components/admin/links.vue";
import Button from "../components/button.vue";
import meetings from "../assets/meetings.json";

export default {
  components: { Links, Intro, Button },
  setup() {
    return {
      meetings,
    };
  },
};
</script>

<style scoped></style>
