<template>
  <div
    :class="color"
    class="
      hover:underline
      shadow-lg
      px-5
      py-5
      text-center
      border-b-8
      space-x-5
      h-full
    "
  >
    <b>{{ input.title }}</b>
    <div></div>
    <div>
      {{ input.person }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["input", "color"],
};
</script>

<style scoped></style>
